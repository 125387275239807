
<template>
<div class="py-4">
  <div v-if="!isLoading" class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Claim overview</h5>
      <div class="card mb-5">
        <div class="card-body">
          <div class="row gy-3 justify-content-between align-items-start">
            <div class="col-10">
              <div class="row g-2">
                <div class="col-6"> 
                  <span>Claim ID</span>
                  <h6> {{item.friendly_id}} </h6>
                </div> 
                <div class="col-6"> 
                  <span>Status</span>
                  <div><span v-html="statusBadge(item.status)"> </span></div>
                </div>
                <div class="col-6"> 
                  <span>Loss type</span>
                  <h6> {{item.loss_type.replace(/_/g, ' ')}} </h6>
                </div>
                <div class="col-sm-6"> 
                  <span>Loss date</span>
                  <h6> {{$filters.date_time(item.loss_date, 1)}} </h6>
                </div>
                <div class="col-sm-6"> 
                  <span>Paypal emal</span>
                  <h6> {{item.paypal_email}} </h6>
                </div>
                <div class="col-sm-6"> 
                  <span>Digital signature</span>
                  <h6> {{ item.digital_signature}} </h6>
                </div>
                <div class="col-12"> 
                  <span>Description</span>
                  <div> {{ item.description}} </div>
                </div>
                <div class="col-12"> 
                  <div><strong>Item:</strong> <router-link :to="`/items/${item.item.id}`"> {{ item.item.title}}</router-link> </div>
                </div>
              </div>
            </div>
            <div class="col-2 text-sm-end">
              <div class="dropdown no-arrow">
                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <router-link :to="`/items/${item.item_id}`" class="dropdown-item">
                    View item </router-link>
                  </li>
                  <li>
                    <a @click.prevent="deleteItem()" href="#" class="dropdown-item">
                     Delete claim  </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <h5 class="mb-3">Claim photos</h5>
      <div class="card mb-5">
        <div class="card-body">
         <div class="row g-4">
            <div v-for="(file, fIndex) in item.files" :key="fIndex"
               class="col-12 col-lg-3 col-sm-6" >
              <div>
                <div @click="showImg(fIndex)" class="cursor-pointer">
                  <img :src="absoluteUrl(file.path)" class="mb-2 w-100" />
                </div>
                <span>{{file.description}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <vue-easy-lightbox v-if="item" :visible="lightbox.visible" :imgs="lightboxFiles"
        :index="lightbox.index" @hide="initializeAll()"></vue-easy-lightbox>
    </div>
  </div>
  <is-loading v-else />
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components:{
    IsLoading,
    VueEasyLightbox,
  },
  data(){
    return {
      isLoading: true,
      item: null,
      lightbox:{
        index: null,
        visible:false,
      },
    }
  },
  watch: {
    '$route.params.claimId': function (id){
      if(id) this.fetchItem()
    },
  },
  computed:{
    lightboxFiles(){
      return this.item.files.map(file => {
        if(file.is_video){
          return {
            title: file.description,
            thumb: this.absoluteUrl(file.path),
            mediaType: 'video',
            src: this.absoluteUrl(file.video_path) }
        }else{
          return {
            title: file.description,
            mediaType: 'image',
            src: this.absoluteUrl(file.path) }
        }
      })
    },
  },
  methods:{
    showImg(index) {
      this.lightbox = { index: index,visible: true};
    },
    deleteItem(){
      this.confirmDelete({
        text: "You are about to delete this claim!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/claims/${this.item.id}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$store.commit("claim/DELETE_CLAIM", this.item.id)
              this.$router.push({path: "/claims"})
            }
          })
        }
      });
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/claims/${this.$route.params.claimId}/show`)
      .then((response) => {
        this.isLoading =  false
        if(response.data.success){
          this.item = response.data.data
        }else{
          this.$router.push({path: "/error-404"})
        }
      })
    },
    initializeAll(){
      this.lightbox = { index: null, visible:false}
    },
  },
  created(){
    this.fetchItem()
  }
}

</script>

<style lang="scss">

</style>
